<template>
  <div class="relative h-full w-full">
    <DeferredPopover
      ref="popoverRef"
      :ui="{
        width: 'min-w-52 max-w-[280px]',
        wrapper: 'h-full'
      }"
      :trigger-class="triggerClass"
      v-bind="$attrs"
      :disabled="disabled"
      data-test="dropdown-field"
      @update:open="$emit('update:open', $event)"
    >
      <template #content>
        <PreviewPopoverContentDropdown
          :selected="selected"
          :disabled="disabled"
          :disable-setting="disableSetting"
          :menu-options="menuOptions"
          :field-id="fieldId"
          :label="label"
          :value="value"
          @change="$emit('change', $event, fieldId)"
          @close="popoverRef.close()"
        />
      </template>
      <div class="flex items-center w-full h-full">
        <div :class="['pl-2 pr-8 flex items-center ']">
          <div
            v-if="selectedOption?.label"
            class="px-2 py-1 rounded"
            :style="getFieldOptionPreset(selectedOption?.color!)"
            data-test="selected-option"
          >
            <Tooltip :text="selectedOption?.label" class="flex">
              <template #default="{ getTextRef }">
                <div
                  :ref="getTextRef"
                  class="line-clamp-1 break-all leading-4 text-xs"
                >
                  {{ selectedOption?.label }}
                </div>
              </template>
            </Tooltip>
          </div>
          <div
            v-else
            class="leading-4 text-xs group-hover/cell:visible invisible"
          >
            -
          </div>
        </div>
        <div
          class="absolute right-1 top-1/2 -translate-y-1/2 flex items-center"
        >
          <UButton
            v-if="selected && !disabled"
            color="gray"
            variant="ghost"
            size="2xs"
            icon="i-heroicons-x-mark"
            class="group-hover/cell:visible invisible"
            @click.prevent.stop="onClear"
          />
          <Icon
            v-else
            name="heroicons:chevron-down"
            class="text-gray-500 group-hover/cell:visible invisible mr-1"
            size="2xs"
          />
        </div>
      </div>
    </DeferredPopover>
  </div>
</template>

<script lang="ts" setup>
import type { FieldOption } from '#field/types'

const props = defineProps<{
  value: string | undefined
  label?: string
  options: string
  preview?: boolean
  fieldId?: string
  disabled?: boolean
  cellClass?: string
  disableSetting?: boolean
  triggerClass?: (active: boolean) => string[]
}>()

const emit = defineEmits<{
  (e: 'change', value: string, id?: string): void
  (e: 'update:open', value: boolean): void
}>()

const popoverRef = ref()
const selected = ref(props.value)

const menuOptions = computed<Array<FieldOption>>(() => {
  return (safeParseStringToJSON(props.options, []) as FieldOption[]).filter(
    (option) => option.label
  )
})

const selectedOption = computed(() => {
  return menuOptions.value.find((option) => option.value === selected.value)
})

const onClear = () => {
  selected.value = ''
  emit('change', '', props.fieldId)
  popoverRef.value.close()
}

watch(
  () => props.value,
  (val) => {
    selected.value = val
  }
)
</script>
