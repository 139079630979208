<template>
  <div v-if="preview">
    {{ previewTitle }}
  </div>
  <PreviewEditableDropdown
    v-else
    v-model:open="isOpen"
    v-bind="$props"
    @change="onChangeValue"
    @update:open="$emit('update:open', $event)"
  />
</template>

<script lang="ts" setup>
import type { FieldOption, TaskFieldRef } from '#field/types'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  containerClass?: string
  value: string | undefined
  label?: string
  options: string
  preview?: boolean
  fieldId?: string
  disabled?: boolean
  taskId?: string
  disableSetting?: boolean
  triggerClass?: (active: boolean) => string[]
}>()

defineEmits<{
  (e: 'update:open', value: boolean): void
}>()

const isOpen = ref(false)
const { updateTaskCustomField } = useUpdateTask()

const menuOptions = computed<Array<FieldOption>>(() => {
  return (safeParseStringToJSON(props.options, []) as FieldOption[]).filter(
    (option) => option.label
  )
})

const selectedOption = computed(() => {
  return menuOptions.value.find((option) => option.value === props.value)
})

const onChangeValue = (value: string, id?: string) => {
  if (!props.taskId || !id) return

  updateTaskCustomField({
    taskIds: [props.taskId],
    fieldId: id,
    value,
    useSelectedTasks: true,
  })
}

const previewTitle = computed(() => {
  return selectedOption.value?.label ? selectedOption.value.label : '-'
})

defineExpose<TaskFieldRef>({
  open: () => {
    isOpen.value = true
  },
  close: () => {
    isOpen.value = false
  },
  delete: () => {
    if (!props.taskId || !props.fieldId) return

    updateTaskCustomField({
      taskIds: [props.taskId],
      fieldId: props.fieldId,
      value: null,
      useSelectedTasks: true,
    })
  },
})
</script>
