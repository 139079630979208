<template>
  <FilterSelect
    :options="menuOptions"
    :selected="selected"
    :disabled="disabled"
    :title="label"
    :selected-option-id="value"
    :search-attributes="['label']"
    placeholder="Search option"
    value-attribute="value"
    :classes=" {
      list: 'max-h-[20.175rem] !pl-0 !pr-0.5 !pb-1 !space-y-1',
      item: '!px-2 !py-1.5 gap-2 text-gray-900 cursor-pointer rounded-md',
    }"
    @change="onSelect"
    @close="$emit('close')"
  >
    <template #item="{ option }">
      <FieldItem
        :checked="option.value === selected"
        :text="option.label"
        :style="getFieldOptionPreset(option.color)"
        hide-checkbox
        class-name="py-1 px-2 rounded-md"
        data-test="dropdown-field-item"
        @change="onSelect(option)"
      />
    </template>
    <template #footer>
      <div v-if="!disableSetting && can('dashboard.data.manage_tasks_sections')" class="p-2 pt-1">
        <UButton
          class="w-full flex justify-center"
          variant="soft"
          color="gray"
          @click="setSettingTab('tasks')"
        >
          Manage options
        </UButton>
      </div>
    </template>
  </FilterSelect>
</template>

<script lang="ts" setup>
import type { FieldOption } from '#field/types'

const props = defineProps({
  selected: {
    type: String
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  fieldId: {
    type: String
  },
  menuOptions: {
    type: Array as PropType<FieldOption[]>,
    required: true,
  },
  disableSetting: {
    type: Boolean
  },
  label: {
    type: String
  },
  value: {
    type: String
  }
})

const emit = defineEmits(['close', 'change'])

const { can } = useBoardAbility()
const { setSettingTab } = useBoardSettingsNavigator()

const onSelect = (option: FieldOption) => {
  if (option.value != props.value) {
    emit('change', option.value, props.fieldId)
    emit('close')
  }
}
</script>
